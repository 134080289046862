<template>
  <v-card class="products">
    <v-card-title>
      <h2>Products</h2>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
    @update:page="goTo"   
    :headers="productHeaders"
    :items="products"
    :footer-props="{
    'items-per-page-options': [10, 25, 50, 100]
    }"
    class="data-table elevation-1"
    :loading="loading"
    loading-text="Loading Products..."
    :items-per-page="50"
    :search="search"
    :no-data-text="noProducts"
    height="70vh"
    fixed-header
  >
  <template
    v-slot:item._sgs_seedtypeid_value="{ item }"
  >
    {{ cornOrSoybeans(item._sgs_seedtypeid_value) }}
  </template>
  <template
    v-slot:item.brand="{ item }"
  >
    Peterson Farms Seed
  </template>
  </v-data-table>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: 'Products',
  async created() {
    if (!this.products.length && this.token) {
      this.loading = true
      await this.$store.dispatch("product/getCRMProducts")
      await this.$store.dispatch("product/getArchivedCRMProducts")
      this.loading = false
    }
  },
  components: {
  },
  data() {
      return {
          loading: false,
          search: "",
          productHeaders: [
            {
                text: "Product Name",
                value: "name"
            },
            {
                text: "Seed Type",
                value: "_sgs_seedtypeid_value"
            },
            {
                text: "Trait",
                value: "pfs_trait"
            },
            {
                text: "Maturity",
                value: "pfs_maturity"
            },
            {
                text: "Brand",
                value: "brand",
            }
        ]
      }
  },
  watch: {
    async token(val) {
      this.loading = true
      await this.$store.dispatch("product/getCRMProducts")
      this.loading = false
    }
  },
  computed: {
    ...mapState("product", ["products"]),
    ...mapState("user", ["token"]),
    ...mapState("product", ["archivedProducts"]),
    noProducts() {
        if (this.dataCheck) {
            return "No Products Found"
        }
        else {
            return "Loading Products..."
        }
    }
  },
  methods: {
    cornOrSoybeans(val) {
      if (val === "b5023b0c-db69-df11-b6f5-a4badb3d68f3") {
        return "Corn"
      }
      else if (val === "0acf3a12-db69-df11-b6f5-a4badb3d68f3") {
        return "Soybeans"
      }
    },
    goTo () {
      this.$vuetify.goTo(
        '.data-table tbody tr',
         {
           offset: 50,
            container: '.v-data-table__wrapper'
         }
        )
    }
  }
}
</script>

<style lang="scss" scoped>
</style>